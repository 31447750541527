<template>
  <div>
    <div class="container" v-if="grouped_invoice">
      <div class="row mb-4">
        <div class="col-sm-12">
          <router-link to="/grouped_invoices">
            <b-button variant="secondary">Show all</b-button>
          </router-link>
        </div>
      </div>
      <b-card :title="'#' + grouped_invoice.invoice_number" :sub-title="grouped_invoice.description" header-tag="header" footer-tag="footer">
        <template #header>
          <strong>{{ grouped_invoice.transaction_date }}</strong>
          <span class="float-right">
            <strong>Remaining amount:</strong> {{ grouped_invoice.remaining_amount }} kr / {{ grouped_invoice.total_amount }} kr
          </span>
        </template>

        <grouped-invoice-invoice-table
          class="mt-4"
          :invoices="grouped_invoice.invoices"
        />

        <template #footer>
          <div class="row mb-4">
            <div class="col-sm-6">
              <div><strong>OCR</strong> {{ grouped_invoice.ocr || '-' }}</div>
              <div><strong>Cost Center</strong> {{ grouped_invoice.cost_center }}</div>
              <div><strong>Invoice method</strong> {{ grouped_invoice.invoice_method }}</div>
              <div><strong>Consignee GLN</strong> {{ grouped_invoice.consignee_gln }}</div>
            </div>
            <div class="col-sm-6">
              <div><strong>Customer reference</strong> {{ grouped_invoice.customer_reference }}</div>
              <div><strong>Period</strong> {{ grouped_invoice.invoice_period_from }} - {{ grouped_invoice.invoice_period_to }}</div>
              <div><strong>Due date</strong> {{ grouped_invoice.due_date }}</div>
            </div>
          </div>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
  import GroupedInvoiceInvoiceTable from "./invoice_table"

  export default {
    name: "groupedInvoice",
    components: {
      GroupedInvoiceInvoiceTable,
    },
    computed: {
      grouped_invoice () {
        return this.$store.state.groupedInvoices.one[this.$route.params.id]
      }
    },
    mounted () {
      if (!this.grouped_invoice) {
        this.fetchGroupedInvoice()
      }
    },
    methods: {
      fetchGroupedInvoice () {
        return this.$store.dispatch('groupedInvoices/get', this.$route.params.id)
      }
    }
  }
</script>
