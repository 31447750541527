<template>
  <div>
    <b-table striped small hover responsive="sm" :fields="fields" :items="rows" custom-foot>
      <template v-slot:cell(unit_price)="data">
        {{ data.item.unit_price }} kr
      </template>
      <template v-slot:cell(vat_amount)="data">
        {{ data.item.vat_amount }} kr
      </template>
      <template v-slot:cell(total_net_price)="data">
        {{ data.item.total_net_price }} kr
      </template>
      <template v-slot:cell(total_gross_price)="data">
        {{ data.item.total_gross_price }} kr
      </template>
      <template #foot()="data">
        <i>Hello</i>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:  "invoiceRowsTable",
    props: {
      rows: Array,
    },
    data () {
      return {
        fields: [{
          key: 'row_number',
          label: '#'
        },{
          key: 'description',
        },{
          key: 'unit_price',
          label: 'Unit Cost'
        },{
          key: 'quantity',
          label: 'Qty'
        },{
          key: 'total_net_price',
          label: 'Total (net)'
        },{
          key: 'vat_amount',
          label: 'VAT'
        },{
          key: 'vat_code',
          label: 'VAT code'
        },{
          key: 'total_gross_price',
          label: 'Total (gross)'
        }],
      }
    },
  }
</script>
