<template>
  <div>
    <div class="container" v-if="invoice">
      <div class="row mb-4">
        <div class="col-sm-12">
          <router-link to="/invoices">
            <b-button variant="secondary">Show all</b-button>
          </router-link>
          <router-link v-if="invoice.grouped_invoice" :to="{ name: 'grouped_invoice', params: { id: invoice.grouped_invoice.id} }">
            <b-button variant="secondary">Show grouped invoice</b-button>
          </router-link>
        </div>
      </div>
      <b-card :title="'#' + invoice.invoice_number" :sub-title="invoice.customer.full_name" header-tag="header" footer-tag="footer">
        <template #header>
          <strong>{{ invoice.transaction_date }}</strong>
          <span class="float-right">
            <strong>Remaining amount:</strong> {{ invoice.remaining_amount }} kr / {{ invoice.total_amount }} kr
          </span>
        </template>

        <hr>

        <div class="row mb-4">
          <div class="col-sm-6 mb-3">
            <h5 class="mb-2">Shipping</h5>
            <div>
              <strong>{{ invoice.shipping_address.addressee }}</strong>
            </div>
            <div>{{ invoice.shipping_address.attention }}</div>
            <div>{{ invoice.shipping_address.addr1 }}</div>
            <div>{{ invoice.shipping_address.addr2 }}</div>
            <div>{{ invoice.shipping_address.postal_code }} {{ invoice.shipping_address.city }}</div>
            <div>{{ invoice.shipping_address.country }}</div>
            <div v-if="invoice.shipping_address.phone_number">Phone: {{ invoice.shipping_address.phone_number }}</div>
          </div>

          <div class="col-sm-6 mb-3">
            <h5 class="mb-2">Billing</h5>
            <div>
              <strong>{{ invoice.billing_address.addressee }}</strong>
            </div>
            <div>{{ invoice.billing_address.attention }}</div>
            <div>{{ invoice.billing_address.addr1 }}</div>
            <div>{{ invoice.billing_address.addr2 }}</div>
            <div>{{ invoice.billing_address.postal_code }} {{ invoice.billing_address.city }}</div>
            <div>{{ invoice.billing_address.country }}</div>
            <div v-if="invoice.billing_address.phone_number">Phone: {{ invoice.billing_address.phone_number }}</div>
          </div>
        </div>

        <invoice-rows-table :rows="invoice.rows"/>

        <div class="col-lg-4 col-sm-5 ml-auto">
          <table class="table table-clear">
            <tbody>
              <tr>
                <td class="left">
                  <strong>Total</strong>
                </td>
                <td class="right">
                  <strong>{{ invoice.total_amount }} kr</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <template #footer>
          <div class="row mb-4">
            <div class="col-sm-6">
              <div><strong>OCR</strong> {{ invoice.ocr }}</div>
              <div><strong>Cost Center</strong> {{ invoice.cost_center }}</div>
              <div><strong>Invoice method</strong> {{ invoice.invoice_method }}</div>
              <div><strong>Service sub type</strong> {{ invoice.service_sub_type }}</div>
            </div>
            <div class="col-sm-6">
              <div><strong>Due date</strong> {{ invoice.due_date }}</div>
              <div><strong>Exported at</strong> {{ invoice.exported_at | formatDateTime }}</div>
              <div><strong>Export received at</strong> {{ invoice.export_received_at | formatDateTime }}</div>
            </div>
          </div>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
  import InvoiceRowsTable from "./rows_table"

  export default {
    name: "invoice",
    components: {
      InvoiceRowsTable,
    },
    computed: {
      invoice () {
        return this.$store.state.invoices.invoices[this.$route.params.id]
      }
    },
    mounted () {
      if (!this.invoice) {
        this.fetchInvoice()
      }
    },
    methods: {
      fetchInvoice () {
        return this.$store.dispatch('invoices/get', this.$route.params.id)
      }
    }
  }
</script>
