export function formatDateTime(date) {
  if (date) {
    return new Date(date).toLocaleString("sv-SE", { dateStyle: "short", timeStyle: "short" })
  }
}

export function formatDate(date) {
  if (date) {
    return new Date(date).toLocaleDateString("sv-SE")
  }
}
