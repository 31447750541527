<template>
  <div>
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      @input="fetchRegions"
      align="right"
    ></b-pagination>
    <b-table
      striped
      small
      hover
      custom-foot
      :no-local-sorting=true
      @sort-changed="sortingChanged"
      :fields="fields"
      :items="regions"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template v-slot:cell(link_to)="data">
        <div class="text-right">
          <router-link :to="{ name: 'region', params: { id: data.item.id } }">
            <b-button variant="secondary" size="sm">Show</b-button>
          </router-link>
        </div>
      </template>
      <template #custom-foot="">
        <b-tr>
          <b-td colspan="8" class="text-right">
            Total: <b>{{ total }}</b>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:  "regionTable",
    data () {
      return {
        sortBy: "id",
        sortDesc: true,
        perPage: 25,
        currentPage: 1,
        query: "",
        fields: [{
          key: "id",
          label: "#",
          sortable: true,
        },{
          key: "name",
          sortable: true,
        },{
          key: "netsuite_internal_id",
          label: "NS id",
          sortable: true,
        },{
          key: "preparation_group_period_in_days",
          label: "Group Preparations in days",
          sortable: true,
        },{
          key: "remainder_group_period_in_days",
          label: "Group Remainders in days",
          sortable: true,
        },{
          key: "bill_remainders_on_set_weekday",
          label: "Bill Remainders on weeekday?",
          sortable: true,
        },{
          key: "bill_remainders_on_weekday",
          label: "Bill Remainders on weekday",
          sortable: true,
        },{
          key: "use_customer_cost_center",
          label: "Use customer's cost center on invoice",
          sortable: true,
        },{
          key: 'link_to',
          label: '',
          sortable: false,
        }],
      }
    },
    computed: {
      regions () {
        return this.$store.state.regions.all
      },
      total () {
        return this.$store.state.regions.count
      }
    },
    mounted () {
      if (this.regions.length == 0) {
        this.fetchRegions()
      }
    },
    methods: {
      sortingChanged (ctx) {
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
        this.fetchRegions()
      },
      fetchRegions (query = "") {
        return this.$store.dispatch('regions/getAll', {
          query: query,
          page: this.currentPage,
          per_page: this.perPage,
          order: this.sortBy,
          order_desc: this.sortDesc,
        })
      }
    }
  }
</script>
