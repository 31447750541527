<template>
  <div>
    <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
      <img :src="Logo" width="110" class="mr-2">
      <h5 class="my-0 mr-md-auto font-weight-normal">Factoria</h5>
      <nav>
        <router-link class="align-middle nav-link d-inline-block text-dark" to="/grouped_invoices">Grouped invoices</router-link>
        <router-link class="align-middle nav-link d-inline-block text-dark" to="/invoices">Invoices</router-link>
        <router-link class="align-middle nav-link d-inline-block text-dark" to="/regions">Regions</router-link>
        <a class="nav-link d-inline-block text-dark py-1" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" href="#" role="button">
          <img class="mr-2 rounded-circle" width="32" height="32" :src="currentUser.image_url" v-if="currentUser && currentUser.image_url">
          <span class="align-middle" v-if="currentUser">
            {{ currentUser.name }}
          </span>
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="/sidekiq">Background jobs</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" rel="nofollow" data-method="delete" href="/users/sign_out">Sign out</a>
        </div>
      </nav>
    </div>

    <div class="container">
      <router-view></router-view>

      <footer class="pt-4 my-md-5 pt-md-5 border-top">
        <div class="row">
          <div class="col-12 col-md">
            <a href="/">
              <img :src="Logo" width="80">
            </a>
            <small class="d-block mb-3 text-muted">Factoria</small>
          </div>
          <div class="col-6 col-md"></div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import Logo from '@/assets/images/logo.svg';

  export default {
    data: () => ({
      Logo,
    }),
    computed: {
      currentUser () {
        return this.$store.state.users.current
      }
    },
    mounted () {
      if (!this.currentUser) {
        this.fetchCurrentUser()
      }
    },
    methods: {
      fetchCurrentUser () {
        return this.$store.dispatch('users/getCurrent')
      }
    },
  }
</script>

<style lang="scss">
  .table td {
    vertical-align: middle;
  }
</style>
