<template>
  <div>
    <invoice-table/>
  </div>
</template>

<script>
  import InvoiceTable from "./table"

  export default {
    name: "invoices",
    components: {
      InvoiceTable,
    },
  }
</script>
