import Vue from "vue"
import VueRouter from "vue-router"

import AppLayout from "@/views/Layout/AppLayout.vue";
import { GroupedInvoices, GroupedInvoice } from "@/views/GroupedInvoices"
import { Invoices, Invoice } from "@/views/Invoices"
import { Regions, Region } from "@/views/Regions"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "grouped_invoices",
    component: AppLayout,
    children: [
      {
        path: "/grouped_invoices",
        name: "grouped_invoices",
        component: GroupedInvoices
      },
      {
        path: "/grouped_invoices/:id",
        name: "grouped_invoice",
        component: GroupedInvoice
      },
      {
        path: "/invoices",
        name: "invoices",
        component: Invoices
      },
      {
        path: "/invoices/:id",
        name: "invoice",
        component: Invoice
      },
      {
        path: "/regions",
        name: "regions",
        component: Regions
      },
      {
        path: "/regions/:id",
        name: "region",
        component: Region
      },
    ]
  },
]

export default new VueRouter({
  mode: "history",
  routes
})
