import axios from "axios"
import Vue from "vue"

export default {
  namespaced: true,
  state: () => ({
    all: [],
    one: {},
    count: null,
  }),
  getters: {
    getAll: (state) => { return state.all },
  },
  actions: {
    getAll ({ commit }, params) {
      axios
        .get("/grouped_invoices", { params: params })
        .then(response => {
          commit("setGroupedInvoices", response.data.grouped_invoices)
          commit("setGroupedInvoicesCount", response.data.pagy.count)
        })
        .catch(e => {
          console.log(e)
        })
    },
    get ({ commit }, id) {
      axios
        .get(`/grouped_invoices/${id}`)
        .then(response => {
          commit("setGroupedInvoice", { id, response })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    setGroupedInvoices(state, grouped_invoices) {
      Vue.set(state, "all", grouped_invoices)
    },
    setGroupedInvoicesCount(state, count) {
      Vue.set(state, "count", count)
    },
    setGroupedInvoice(state, { id, response }) {
      Vue.set(state.one, id, response.data)
    },
  },
}
