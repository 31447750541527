import axios from "axios"
import Vue from "vue"

export default {
  namespaced: true,
  state: () => ({
    current: null,
  }),
  getters: {
    getCurrent: (state) => { return state.current },
  },
  actions: {
    getCurrent ({ commit }, params) {
      axios
        .get("/users/current")
        .then(response => {
          commit("setCurrentUser", response)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    setCurrentUser(state, user) {
      Vue.set(state, "current", user.data)
    },
  },
}
