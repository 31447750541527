<template>
  <div>
    <b-row>
      <b-col sm="5" md="6">
        <b-form inline align="left">
          <label class="sr-only" for="inline-form-input-name">Name</label>
          <b-form-input
            id="inline-form-input-name"
            v-model="query"
            debounce="500"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Invoice number"
            @input="fetchInvoices($event)"
          ></b-form-input>
        </b-form>
      </b-col>
      <b-col sm="7" md="6">
      <b-pagination
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        @input="fetchInvoices"
        align="right"
      ></b-pagination>
      </b-col>
    </b-row>
    <b-table
      striped
      small
      hover
      custom-foot
      :no-local-sorting=true
      @sort-changed="sortingChanged"
      :fields="fields"
      :items="invoices"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template v-slot:cell(invoice_number)="data">
        <b-tooltip v-bind:target="'invoice-grouped-tooltip-' + data.item.id" triggers="hover">
          I'm in a group invoice!
        </b-tooltip>
        <b-icon-folder-check v-if="data.item.grouped_invoice" v-bind:id="'invoice-grouped-tooltip-' + data.item.id"></b-icon-folder-check>
        {{ data.item.invoice_number }}
      </template>
      <template v-slot:cell(invoice_customers.customer_number)="data">
        {{ data.item.customer.full_name }}
      </template>
      <template v-slot:cell(total_amount)="data">
        {{ data.item.total_amount }} kr
      </template>
      <template v-slot:cell(link_to)="data">
        <div class="text-right">
          <router-link :to="{ name: 'invoice', params: { id: data.item.id} }">
            <b-button variant="secondary" size="sm">Show</b-button>
          </router-link>
        </div>
      </template>
      <template #custom-foot="">
        <b-tr>
          <b-td colspan="6" class="text-right">
            Total: <b>{{ total }}</b>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:  "invoiceTable",
    data () {
      return {
        sortBy: "id",
        sortDesc: true,
        perPage: 25,
        currentPage: 1,
        query: "",
        fields: [{
          key: "id",
          label: "#",
          sortable: true,
        },{
          key: "invoice_number",
        },{
          key: "transaction_date",
          sortable: true,
        },{
          key: "invoice_customers.customer_number",
          label: "Customer",
          sortable: true,
          sortKey: "invoice_customers.customer_number",
        },{
          key: "total_amount",
          label: "Total",
        },{
          key: "link_to",
          label: "",
        }],
      }
    },
    computed: {
      invoices () {
        return this.$store.state.invoices.all
      },
      total () {
        return this.$store.state.invoices.count
      }
    },
    mounted () {
      if (this.invoices.length == 0) {
        this.fetchInvoices()
      }
    },
    methods: {
      sortingChanged (ctx) {
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
        this.fetchInvoices()
      },
      fetchInvoices (query = "") {
        return this.$store.dispatch('invoices/getAll', {
          query: query,
          page: this.currentPage,
          per_page: this.perPage,
          order: this.sortBy,
          order_desc: this.sortDesc,
        })
      }
    }
  }
</script>
