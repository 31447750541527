<template>
  <div>
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      @input="fetchGroupedInvoices"
      align="right"
    ></b-pagination>
    <b-table
      striped
      small
      hover
      custom-foot
      :no-local-sorting=true
      @sort-changed="sortingChanged"
      :fields="fields"
      :items="groupedInvoices"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
    >
      <template v-slot:cell(link_to)="data">
        <div class="text-right">
          <router-link :to="{ name: 'grouped_invoice', params: { id: data.item.id } }">
            <b-button variant="secondary" size="sm">Show</b-button>
          </router-link>
        </div>
      </template>
      <template #custom-foot="">
        <b-tr>
          <b-td colspan="6" class="text-right">
            Total: <b>{{ total }}</b>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:  "groupedInvoiceTable",
    data () {
      return {
        sortBy: "id",
        sortDesc: true,
        perPage: 25,
        currentPage: 1,
        fields: [{
          key: "id",
          label: "#",
          sortable: true,
        },{
          key: 'invoice_number',
        },{
          key: 'transaction_date',
          sortable: true,
        },{
          key: 'customer_reference',
          label: "Customer",
          sortable: true,
          sortKey: 'customer_reference'
        },{
          key: 'description',
        },{
          key: 'link_to',
          label: ''
        }],
      }
    },
    computed: {
      groupedInvoices () {
        return this.$store.state.groupedInvoices.all
      },
      total () {
        return this.$store.state.groupedInvoices.count
      }
    },
    mounted () {
      if (this.groupedInvoices.length == 0) {
        this.fetchGroupedInvoices()
      }
    },
    methods: {
      sortingChanged (ctx) {
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
        this.fetchGroupedInvoices()
      },
      fetchGroupedInvoices () {
        return this.$store.dispatch('groupedInvoices/getAll', {
          page: this.currentPage,
          per_page: this.perPage,
          order: this.sortBy,
          order_desc: this.sortDesc,
        })
      }
    }
  }
</script>
