import Vue from "vue"
import Vuex from "vuex"

import groupedInvoices from "./modules/grouped_invoices"
import invoices from "./modules/invoices"
import regions from "./modules/regions"
import users from "./modules/users"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    groupedInvoices,
    invoices,
    regions,
    users,
  },
})
