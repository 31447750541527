<template>
  <div>
    <region-table/>
  </div>
</template>

<script>
  import RegionTable from "./table"

  export default {
    name: "regions",
    components: {
      RegionTable,
    },
  }
</script>
