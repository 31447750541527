<template>
  <div>
    <grouped-invoice-table/>
  </div>
</template>

<script>
  import GroupedInvoiceTable from "./table"

  export default {
    name: "groupedInvoices",
    components: {
      GroupedInvoiceTable,
    },
  }
</script>
