import axios from "axios"
import Vue from "vue"

export default {
  namespaced: true,
  state: () => ({
    all: [],
    one: {},
    count: null,
  }),
  getters: {
    getAll: (state) => { return state.all },
  },
  actions: {
    getAll ({ commit }, params) {
      axios
        .get("/regions", { params: params })
        .then(response => {
          commit("setRegions", response.data.regions)
          commit("setRegionsCount", response.data.pagy.count)
        })
        .catch(e => {
          console.log(e)
        })
    },
    get ({ commit }, id) {
      axios
        .get(`/regions/${id}`)
        .then(response => {
          commit("setRegion", { id, response })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    setRegions(state, regions) {
      Vue.set(state, "all", regions)
    },
    setRegionsCount(state, count) {
      Vue.set(state, "count", count)
    },
    setRegion(state, { id, response }) {
      Vue.set(state.one, id, response.data)
    },
  },
}
