import axios from "axios"
import Vue from "vue"

export default {
  namespaced: true,
  state: () => ({
    all: [],
    invoices: {},
    count: null,
  }),
  getters: {
    getAll: (state) => { return state.all },
  },
  actions: {
    getAll ({ commit }, params) {
      axios
        .get("/invoices", { params: params })
        .then(response => {
          commit("setInvoices", response.data.invoices)
          commit("setInvoicesCount", response.data.pagy.count)
        })
        .catch(e => {
          console.log(e)
        })
    },
    get ({ commit }, id) {
      axios
        .get(`/invoices/${id}`)
        .then(response => {
          commit("setInvoice", { id, response })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    setInvoices(state, invoices) {
      Vue.set(state, "all", invoices)
    },
    setInvoicesCount(state, count) {
      Vue.set(state, "count", count)
    },
    setInvoice(state, { id, response }) {
      Vue.set(state.invoices, id, response.data)
    },
  },
}
