<template>
  <div>
    <div class="container" v-if="region">
      <div class="row mb-4">
        <div class="col-sm-12">
          <router-link to="/regions">
            <b-button variant="secondary">Show all</b-button>
          </router-link>
        </div>
      </div>
      <b-card :title="'#' + region.name" footer-tag="footer">
        <template #footer>
          <div class="row mb-4">
            <div class="col-sm-6">
              <div><strong>Group Preparations in days</strong> {{ region.preparation_group_period_in_days }}</div>
              <div><strong>Group Remainders in days</strong> {{ region.remainder_group_period_in_days }}</div>
              <div><strong>Bill Remainders on weeekday?</strong> {{ region.bill_remainders_on_set_weekday }}</div>
              <div><strong>Bill Remainders on which weekday</strong> {{ region.bill_remainders_on_weekday }}</div>
            </div>
            <div class="col-sm-6">
              <div><strong>Use customer's cost center on invoice</strong> {{ region.use_customer_cost_center }}</div>
              <div><strong>Netsuite internal id</strong> {{ region.netsuite_internal_id }}</div>
              <div><strong>Created at</strong> {{ region.created_at | formatDateTime }}</div>
              <div><strong>Updated at</strong> {{ region.updated_at | formatDateTime }}</div>
            </div>
          </div>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
  import RegionTable from "./table"

  export default {
    name: "region",
    components: {
      RegionTable,
    },
    computed: {
      region () {
        return this.$store.state.regions.one[this.$route.params.id]
      }
    },
    mounted () {
      if (!this.region) {
        this.fetchRegion()
      }
    },
    methods: {
      fetchRegion () {
        return this.$store.dispatch('regions/get', this.$route.params.id)
      }
    }
  }
</script>
