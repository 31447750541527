import Vue from "vue"

import _ from "@/plugins/bootstrap"

import axios from "axios"
import router from "routers/"
import store from "store/"
import Filters from "filters"
import App from "../App.vue"

Vue.use(Filters);

var csrfMetaTag = document.querySelector('meta[name="csrf-token"]')

if (csrfMetaTag) { axios.defaults.headers.common['X-CSRF-Token'] = csrfMetaTag.getAttribute('content') }
axios.defaults.headers['Content-type'] = 'application/json'
axios.defaults.baseURL = '/api'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
