<template>
  <div>
    <b-table
      striped
      small
      hover
      :fields="fields"
      :items="invoices"
    >
      <template v-slot:cell(total_amount)="data">
        {{ data.item.total_amount }} kr
      </template>
      <template v-slot:cell(link_to)="data">
        <div class="text-right">
          <router-link :to="{ name: 'invoice', params: { id: data.item.id } }">
            <b-button variant="secondary" size="sm">Show</b-button>
          </router-link>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    name:  "groupedInvoiceInvoiceTable",
    props: {
      invoices: Array,
    },
    data () {
      return {
        fields: [{
          key: "id",
          label: "#",
        },{
          key: 'invoice_number',
        },{
          key: 'transaction_date',
        },{
          key: "total_amount",
          label: "Total",
        },{
          key: 'link_to',
          label: ''
        }],
      }
    },
  }
</script>
